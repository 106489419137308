import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './taxonomyObjetive.module.scss';
import { ObraProperties } from '../../../../types/ObraDetails';
import TitleHelp from '../../components/titleHelp/titleHelp';
import InfoBoxBoolean from '../../components/InfoBoxBoolean/InfoBoxBoolean';
import { ReactComponent as checkFalse } from '../../../../assets/check_false_disable.svg';
import TitleValue from '../../components/titleValue/titleValue';
import { Grid } from '@mui/material';

interface TaxonomyObjetiveProps {
  obra: ObraProperties,
  errors?: any
}

const TaxonomyObjetive: FC<TaxonomyObjetiveProps> = (props) => {
  const { obra } = props;
  const { t } = useTranslation();

  let ccm = false, cca = false, bio = false, wtr = false, ce = false, ppc = false;

  const taxoObject = obra.esg.objetivosTaxonomicos || [];

  taxoObject.forEach(to => {
    if (to.codObjetivo === 'CCM') {
      ccm = true;
    } else if (to.codObjetivo === 'CCA') {
      cca = true;
    } else if (to.codObjetivo === 'BIO') {
      bio = true;
    } else if (to.codObjetivo === 'WTR') {
      wtr = true;
    } else if (to.codObjetivo === 'CE') {
      ce = true;
    } else if (to.codObjetivo === 'PPC') {
      ppc = true;
    }
  });

  return (
    <div data-testid='esg-test' id='esg-nodata' className={styles.container}>
      <TitleHelp
        id='taxonomyObjetive'
        value={t('details.esg.taxonomyObjetive.helper')}
        help={t('help.esg.taxonomicObjective')}
      />
      <div className={styles.sectionFlexContainer}>
        <div>
          <div className={styles.taxom}>
            <InfoBoxBoolean
              identifier='taxonomyObjetive'
              item={{ title: '', value: t('details.esg.taxonomyObjetive.CCM') }}
              value={ccm}
              iconFalse={!ccm ? checkFalse : undefined}
              styles={
                { box: ccm ? styles.taxomBoxGreen : styles.taxomBox, value: styles.taxomValue }
              }
            />
          </div>
          <div className={styles.taxom}>
            <InfoBoxBoolean
              identifier='taxonomyObjetive'
              item={{ title: '', value: t('details.esg.taxonomyObjetive.CCA') }}
              value={cca}
              iconFalse={!cca ? checkFalse : undefined}
              styles={
                { box: cca ? styles.taxomBoxGreen : styles.taxomBox, value: styles.taxomValue }
              }
            />
          </div>
          <div className={styles.taxom}>
            <InfoBoxBoolean
              identifier='taxonomyObjetive'
              item={{ title: '', value: t('details.esg.taxonomyObjetive.WTR') }}
              value={wtr}
              iconFalse={!wtr ? checkFalse : undefined}
              styles={
                { box: wtr ? styles.taxomBoxGreen : styles.taxomBox, value: styles.taxomValue }
              }
            />
          </div>
        </div>
        <div>
          <div className={styles.taxom}>
            <InfoBoxBoolean
              identifier='taxonomyObjetive'
              item={{ title: '', value: t('details.esg.taxonomyObjetive.CE') }}
              value={ce}
              iconFalse={!ce ? checkFalse : undefined}
              styles={
                { box: ce ? styles.taxomBoxGreen : styles.taxomBox, value: styles.taxomValue }
              }
            />
          </div>
          <div className={styles.taxom}>
            <InfoBoxBoolean
              identifier='taxonomyObjetive'
              item={{ title: '', value: t('details.esg.taxonomyObjetive.PPC') }}
              value={ppc}
              iconFalse={!ppc ? checkFalse : undefined}
              styles={
                { box: ppc ? styles.taxomBoxGreen : styles.taxomBox, value: styles.taxomValue }
              }
            />
          </div>
          <div className={styles.taxom}>
            <InfoBoxBoolean
              identifier='taxonomyObjetive'
              item={{ title: '', value: t('details.esg.taxonomyObjetive.BIO') }}
              value={bio}
              iconFalse={!bio ? checkFalse : undefined}
              styles={
                { box: bio ? styles.taxomBoxGreen : styles.taxomBox, value: styles.taxomValue }
              }
            />
          </div>
        </div>
      </div>
      <div>

        {
          taxoObject.map((to: any, i: number) => {
            return (
              <div key={i} className={styles.container}>
                <div className={styles.dapClient}>
                  <TitleValue type='input' editable={false} onlyView identifier='socialSafeguards' title={t('details.esg.taxonomyObjetive.target')} value={t('details.esg.taxonomyObjetive.' + to.codObjetivo)} />
                </div>
                <div className={styles.containerList}>
                  {to.actividades.map((a: any, j: number) => {
                    console.log(a);
                    return <Grid container spacing={2} key={j}>
                      <Grid
                        item
                        xs={0.4}
                      ><div className={j === 0 ? styles.branchTree : styles.branchTreeLarge}></div></Grid>
                      <Grid container item xs spacing={2}>
                        <Grid item xs={4}>
                          <TitleValue
                            type='input'
                            editable={false}
                            identifier='socialSafeguards'
                            title={t('details.esg.taxonomyObjetive.codeActivity')}
                            value={a.codActividad}
                            onlyView
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TitleValue
                            type='input'
                            editable={false}
                            identifier='socialSafeguards'
                            title={t('details.esg.taxonomyObjetive.nameActivity')}
                            value={a.nomActividad}
                            onlyView
                          />
                        </Grid>
                      </Grid>
                    </Grid>;
                  }
                  )}
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};


export default TaxonomyObjetive;
