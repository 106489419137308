import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObraProperties, UnidadObra } from '../../../../types/ObraDetails';
import CollapsableField from '../../../../components/collapsableField/collapsableField';
import TitleHelp from '../../components/titleHelp/titleHelp';
import styles from './workUnits.module.scss';
import technicalNoData from '../../../../assets/technicalNoData.svg';
import ObrasAPI from '../../../../services/APIObras';
import { AuthContext } from '../../../../services/authContextProvider';
import { UserModeContext } from '../../../../context/UserModeProvider';
import NoDataPicture from '../../components/noDataPicture/noDataPicture';
import AddItemButton from '../../components/addItemButton/addItemButton';
import { SaveContext } from '../../../../context/SaveProvider';
import WorkUnit from './workUnit/workUnit';
import { TechnicalAspectsContext } from '../technicalAspects';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WorkUnitsProps {
  obra: ObraProperties;
  errors?: any[];
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const WorkUnits: FC<WorkUnitsProps> = (props) => {
  const { obra, errors } = props;
  const [t] = useTranslation();
  const authContext = useContext(AuthContext);
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';
  const saveContext = useContext(SaveContext);
  const { buildingProcessUnits } = useContext(TechnicalAspectsContext);
  const [units, setUnits] = useState(obra.aspectosTecnicos?.unidades.filter(u => !u.unidad?.mandatory).sort((a,b) => Number(a.unidad?.codUnidad) - Number(b.unidad?.codUnidad)) || []);
  const [changeUnits, setChangeUnits] = useState(false);
  const [options, setOptions] = useState<any>();
  const api = ObrasAPI.getInstance();

  const mergeDisableUnits = () => {
    const mergedUnits: UnidadObra[] = [];
    const mergedUnitCodes: Set<string> = new Set();

    obra.aspectosTecnicos?.unidades.forEach((unit) => {
      if (unit.unidad?.mandatory && !mergedUnitCodes.has(unit.unidad.codUnidad)) {
        mergedUnits.push(unit);
        mergedUnitCodes.add(unit.unidad.codUnidad);
      }
    });

    buildingProcessUnits.forEach((processUnits) => {
      processUnits.units?.forEach((unit) => {
        if (!mergedUnitCodes.has(unit.unidad?.codUnidad as string)) {
          mergedUnits.push({ ...unit });
          mergedUnitCodes.add(unit.unidad?.codUnidad as string);
        }
      });
    });

    return mergedUnits.sort((a, b) => Number(a.unidad?.codUnidad) - Number(b.unidad?.codUnidad));
  };

  const disableUnits = mergeDisableUnits();

  useEffect(() => {
    obra.aspectosTecnicos.unidades = [...obra.aspectosTecnicos.unidades.filter(u => u.unidad?.mandatory), ...units];
    const promise = getWorkUnits();
    promise.then(results => {
      const codsToDelete = units.concat(disableUnits).map((u) => u.unidad?.codUnidad);
      setOptions(results.filter((r: any) => 
        !codsToDelete.includes(r.code)
      ));
    }).catch((error) => console.log(error));
  }, [units]);

  const getWorkUnits = async () => {
    return await api.getWorkUnits();
  };

  // adds a new unit registry with empty data
  const handleAddUnit = () => {
    const newUnit = {
      id: undefined,
      idProceso: undefined,
      unidad: undefined,
      medicion: undefined
    };
    setUnits([...units, newUnit]);
    if (obra.aspectosTecnicos?.unidades) {
      obra.aspectosTecnicos.unidades.push(newUnit);
    } else {
      obra.aspectosTecnicos.unidades = [newUnit];
    }
    saveContext.handleChangeSave(true);
  };

  const unitsErrors = errors
  && (errors.filter((err) => err.field === 'unidades').length > 0
    || errors.filter((err) => err.field === 'procesosConstructivos' && err.fieldField === 'medicion').length > 0);

  /**
   * Show errors in the title if the validation is enable and there are errors in the units
   * @returns string for show the error message
   */
  const showErrorInTitle = () => {
    return unitsErrors && disableUnits.concat(units).some((u) => (u.medicion === null || u.medicion === undefined || !u.unidad?.nomUnidad)) ? ' ' : undefined;
  };

  return (
    <div className={styles.workUnitsContainer}>
      <CollapsableField
        id='workUnitsCollapsable'
        title={
          <TitleHelp
            id='workUnitsCollapsable-title'
            value={t('details.technical.workUnits')}
            help={t('help.technicalAspects.workUnits')}
            error={showErrorInTitle()} />
        }>
        <>
          {isEditorMode &&
            <div className={styles.addBtnContainer}>
              <div className={styles.addBtn}>
                <AddItemButton
                  addItemText={t('details.technical.addUnits') as string}
                  handleAddItem={handleAddUnit} />
              </div>
            </div>
          }
          {obra.aspectosTecnicos.unidades?.length > 0 ||
            obra.aspectosTecnicos.procesosConstructivos.map(pc => pc.unidades).length > 0 ?
            <table>
              {
                //First, we render the mandatory units for all projects
                disableUnits.map((mandUnit, i) => {
                  let handleChangeMeasuring;
                  // show errors if they are activated and medicion is null
                  const error = !!(unitsErrors && (mandUnit.medicion === undefined || mandUnit.medicion === null));
                  if (mandUnit.unidad?.mandatory) {
                    handleChangeMeasuring = (measuring: string) => {
                      const formattedMeasuring = measuring.replace(/\./g, '').replace(',', '.');
                      obra.aspectosTecnicos.unidades.forEach(unit => {
                        if (unit.unidad?.codUnidad === mandUnit.unidad?.codUnidad) {
                          unit.medicion = measuring === '' ? undefined : Number(formattedMeasuring);
                        }
                      });
                      saveContext.handleChangeSave(true);
                    };
                  } else {
                    handleChangeMeasuring = (measuring: string) => {
                      obra.aspectosTecnicos.procesosConstructivos.forEach(pc => {
                        pc.unidades.forEach(u => {
                          if (u.unidad?.codUnidad === mandUnit.unidad?.codUnidad) {
                            u.medicion = measuring === '' ? undefined : Number(measuring);
                          }
                        });
                      });
                      saveContext.handleChangeSave(true);
                    };
                  }
                  return <WorkUnit
                    key={i}
                    unit={mandUnit}
                    error={error}
                    master={options}
                    handleChangeMeasuring={handleChangeMeasuring} />;
                })
              }
              {/* Then, we render the not related to any process units */}
              {units.map((uw, i) => {
                const handleChangeUnit = (id: number, codUnidad: string, nomUnidad: string) => {
                  const updatedUnits = [...units];
                  updatedUnits[i].unidad = { id, codUnidad, nomUnidad };
                  setUnits(units);
                  setChangeUnits(changeUnits);
                  saveContext.handleChangeSave(true);
                };
                const handleChangeMeasuring = (measuring: string) => {
                  const formattedMeasuring = measuring.replace(/\./g, '').replace(',', '.');  
                  const updatedUnits = [...units];
                  updatedUnits[i].medicion = measuring === '' ? undefined : Number(formattedMeasuring);
                  setUnits(units);
                  saveContext.handleChangeSave(true);
                };
                const handleDeleteItem = () => {
                  const updatedUnits = [...units];
                  updatedUnits.splice(i, 1);
                  setUnits(updatedUnits);
                  saveContext.forceUpdate();
                  saveContext.handleChangeSave(true);
                };
                // show errors if they are activated and medicion is null
                const error = !!(unitsErrors && (uw.medicion === undefined || uw.medicion === null));
                return <WorkUnit
                  key={i}
                  unit={uw}
                  handleChangeUnit={handleChangeUnit}
                  handleChangeMeasuring={handleChangeMeasuring}
                  handleDeleteItem={handleDeleteItem}
                  error={error}
                  master={options}
                  editable={isEditorMode && !uw.idGrupoProceso && !uw.idProceso} />;
              })}
            </table>
            :
            <NoDataPicture
              id='workUnits-nodata'
              picture={technicalNoData}
              text={authContext.hasEditorRole()
                ? (userModeContext.userMode === 'viewer'
                  ? t('details.technical.changeUserAddWorkUnits')
                  : (userModeContext.userMode === 'editorDisabled'
                    ? t('details.technical.noData') + '. ' + t('details.validation.noEditMode')
                    : t('details.technical.noData')))
                : t('details.technical.noData')}
            />
          }
        </>
      </CollapsableField>
    </div>
  );
};

export default WorkUnits;
