import React, { FC, useContext } from 'react';
import styles from './permissionError.module.scss';
import errorExclamation from '../../assets/error.svg';
import Header from '../../components/header/header';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/authContextProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface permissionErrorProps {
  error: string,
}

const permissionError: FC<permissionErrorProps> = (props) => {
  const { error } = props;
  const [t, i18n] = useTranslation();
  return (
    <div className={styles.permissionError} data-testid="permissionError-test">
      <Header permissionError />
      <div className={styles.boxError}>
        <img src={errorExclamation} />
        <div id="permisionerror_description" className={styles.titleError}>
          <div>{t('permissionsError.description')}</div>
          {
            error === 'user' && <div className={styles.detail}>{t('permissionsError.detail')}</div>
          }
        </div>
      </div>
    </div>
  );
};

export default permissionError;
