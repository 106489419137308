import { FC, useContext, useEffect, useState, FocusEvent, KeyboardEvent, useMemo } from 'react';
import styles from './titleValue.module.scss';
import DatePicker from '../datePicker/datePicker';
import { Typography, InputAdornment, SvgIcon } from '@mui/material';
import { ReactComponent as date } from '../../../../assets/calendar.svg';
import { ReactComponent as input_error } from '../../../../assets/input_error.svg';
import { SaveContext } from '../../../../context/SaveProvider';
import InfoBox from '../infoBox/infoBox';
import { UserModeContext } from '../../../../context/UserModeProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TitleValueProps {
  identifier: string;
  children?: JSX.Element;
  title: string;
  value: any;
  valueColor?: string;
  editable?: boolean;
  handleChange?: (s: string) => any;
  onBlur?: (name: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  valueMaxLength?: number;
  type?: 'date' | 'input' | 'autocomplete';
  isNumber?: boolean;
  parseText?: string;
  contrast?: boolean;
  startIcon?: true;
  isDecimalNumber?: boolean;
  error?: boolean;
  helperText?: string;
  noSpecialCharacters?: boolean;
  maxDate?: any;
  minDate?: any;
  viewStyle?: Record<string, any>;
  onlyView?: boolean;
  onlyDisable?: boolean;
  isTable?: boolean;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const TitleValue: FC<TitleValueProps> = (props) => {
  const {
    identifier,
    children,
    title,
    value,
    valueColor,
    editable,
    handleChange,
    onBlur,
    onKeyDown,
    valueMaxLength,
    parseText,
    type = 'input',
    isNumber,
    noSpecialCharacters,
    contrast = false,
    startIcon,
    isDecimalNumber,
    error,
    helperText,
    maxDate,
    minDate,
    viewStyle,
    onlyView = false,
    onlyDisable = false,
    isTable = false,
  } = props;
  const [valueState, setValueState] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const saveContext = useContext(SaveContext);
  const parseTextRe = parseText && new RegExp(parseText, 'gi');
  const userModeContext = useContext(UserModeContext);
  const isEditorMode = userModeContext.userMode === 'editor';

  // update values when props change
  useEffect(() => {
    setValueState(value);
  }, [value]);

  const handleDecimalChange = (value: string) => {
    // Allows numbers and a comma for decimals
    const sanitizedValue = value.replace(/[^0-9,]/g, '');

    // Split the integer and decimal parts
    const [integerPart, decimalPart] = sanitizedValue.split(',');

    // Limit integer part to 16 characters
    const limitedIntegerPart = integerPart.slice(0, 16);

    // Limit decimal part to 2 characters
    const limitedDecimals = decimalPart ? decimalPart.slice(0, 2) : undefined;

    // Join the parts
    return limitedDecimals !== undefined
      ? `${limitedIntegerPart},${limitedDecimals}`
      : sanitizedValue.slice(0, 16);
  };

  const formatWithThousandsSeparator = (value: string): string => {
    if (!value) {
      return value;
    }
    // Split the integer and decimal parts
    const [integerPart, decimalPart] = value.split(',');

    // Remove leading zeros from the integer part
    const integerWithoutLeadingZeros = integerPart.replace(/^0+(?!$)/, '');

    // Format the integer part with dots
    const formattedInteger = integerWithoutLeadingZeros.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Join the integer and decimal parts
    return decimalPart !== undefined
      ? `${formattedInteger},${decimalPart}`
      : formattedInteger;
  };

  const removeThousandsSeparator = (value: string): string => {
    // Remove dots from the integer part
    return value?.replace(/\./g, '');
  };

  const readOnly = !(editable && handleChange) as boolean;

  let classNameDiv = !readOnly ? styles.editableTitleValueContainer : styles.editableTitleValueContainerRO;

  // classNameDiv = classNameDiv + ' ' + (contrast ? styles.contrast : '');

  classNameDiv = useMemo(() => classNameDiv + ' ' + ((contrast && !isFocused) ? styles.contrast : ''), [isFocused, contrast]);

  classNameDiv = classNameDiv + ' ' + ((type === 'autocomplete') ? styles.editableTitleValueAutocomplete : '');

  classNameDiv = classNameDiv + ' ' + (error ? styles.error : '');


  const handleChangeInput = (s: string) => {
    handleChange ? handleChange(s) : null;
    if (error) {
      saveContext.forceUpdate();
    }
  };

  return (
    <div>
      {editable ?
        (<div className={classNameDiv}>
          <div id={`${identifier}-title`} className={`${startIcon && styles.titleIcon} ${styles.title}`}>
            {title}
          </div>
          {
            type && type === 'date' && !readOnly ?
              <DatePicker data-testid={'date-input-' + title} value={value} handleChange={(newValue) => handleChangeInput(newValue) }
                maxDate={maxDate}
                minDate={minDate}
                inputProps={
                  {
                    startAdornment: startIcon && (
                      <InputAdornment position="start">
                        <SvgIcon component={date} inheritViewBox />
                      </InputAdornment>
                    ),
                    endAdornment: error && (
                      <InputAdornment position="end">
                        <SvgIcon component={input_error} inheritViewBox />
                      </InputAdornment>
                    )
                  }
                } />
              :
              (handleChange && type === 'input')
                ? (<>
                  <div className={styles.input}>
                    <input type={isNumber ? 'number' : 'text'} value={valueState} maxLength={valueMaxLength}
                      //min="0"
                      id={identifier}
                      name={identifier}
                      onBlur={(ev: FocusEvent<HTMLInputElement>) => {
                        if (isDecimalNumber){
                          setValueState((prevValue: string) => formatWithThousandsSeparator(prevValue));
                        }
                        setIsFocused(false);
                        return onBlur && onBlur(ev);
                      } }
                      onFocus={() => {
                        if (isDecimalNumber){
                          setValueState((prevValue: string) => removeThousandsSeparator(prevValue));
                        }
                        setIsFocused(true);
                      }
                      } 
                      onChange={(e) => {
                        const str = (parseTextRe
                          ? e.target.value.replace(parseTextRe, '')
                          : (isNumber
                            ? e.target.value.replace(/[^0-9.]/gi, '')
                            : (isDecimalNumber
                              ? handleDecimalChange(e.target.value)
                              : (noSpecialCharacters
                                ? e.target.value.replace(/[^0-9A-Za-z À-ÿ\u00f1\u00d1-]/gi, '')
                                : e.target.value))));
                        setValueState(str);
                        handleChangeInput(str);
                      }}
                      {...(onKeyDown && {onKeyDown})}
                    />
                    {error && <i className={styles.endIcon}>
                      <SvgIcon component={input_error} inheritViewBox />
                    </i>}
                  </div>
                  {error && helperText && <Typography variant="caption" display="block" mt={1.5} bgcolor="transparent" color="red">
                    {helperText}
                  </Typography>}
                </>
                )
                : (type === 'autocomplete' && children)
          }
        </div>)
        :
        ((!isEditorMode || onlyView) && !onlyDisable ? <InfoBox
          identifier='protectedSpaces'
          item={{ title, value }}
          customStyle={{minWidth: '200px', value: {color: valueColor}}}
          overwriteStyle={viewStyle ?? {}}
        /> : <div className={`${startIcon && styles.titleValueIcon} ${styles.titleValueContainer} ${isTable && styles.tableMargin}`}>
          {startIcon && <div className={styles.startIcon}>
            <SvgIcon component={date} inheritViewBox />
          </div>}
          <div>
            <div id={`${identifier}-title`} className={styles.title}>{title}</div>
            <div id={`${identifier}-value`} className={styles.value} style={valueColor ? { color: valueColor } : {}}>{value}</div>
          </div>

        </div>)
      }
    </div >
  );
};

export default TitleValue;
