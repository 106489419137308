/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import styles from './adminPanelButton.module.scss';
import adminIcon from '../../assets/adminIcon.svg';
import { useTranslation } from 'react-i18next';

interface AdminPanelButtonProps {
  onClick: () => any;
}

const AdminPanelButton: FC<AdminPanelButtonProps> = (props) => {
  const { onClick } = props;
  const [t] = useTranslation();
  
  return (
    <>{
      <div id='header-adminPanel' className={styles.adminPanel} onClick={onClick} >
        <img src={adminIcon} />
        <div className={styles.adminPanelText}>{t('header.adminPanel')}</div>
      </div>
    }</>
  );
};

export default AdminPanelButton;
