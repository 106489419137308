import { TFunction } from 'i18next';
import * as yup from 'yup';
import moment from 'moment';

// Function to validate dates
const validateDates = (fechasPlazos: any, clasificacion: any) => {
  const finishTime = fechasPlazos.fecFinalizacionActualizada
    ? moment(fechasPlazos.fecFinalizacionActualizada, 'DD/MM/YYYY')
    : moment(fechasPlazos.fecFinalizacionInicial, 'DD/MM/YYYY');

  if (
    (fechasPlazos.fecActaRecepcion && fechasPlazos.fecActaRecepcion !== '') ||
    (fechasPlazos.fecFinEjecucion && fechasPlazos.fecFinEjecucion !== '')
  ) {
    const isOnTime = moment().isAfter(finishTime.clone().subtract(30, 'days'));
    return isOnTime;
  }

  const classificationGroups = clasificacion.grupos.filter((group: any) => group.importeFinal);
  if (classificationGroups.length > 0) {
    const isOnTime = moment().isAfter(finishTime.clone().subtract(30, 'days'));
    return isOnTime;
  }

  return true;
};

const isOnTime = (fechasPlazos: any) => {
  const finishTime = fechasPlazos.fecFinalizacionActualizada
    ? moment(fechasPlazos.fecFinalizacionActualizada, 'DD/MM/YYYY')
    : moment(fechasPlazos.fecFinalizacionInicial, 'DD/MM/YYYY');
  return moment().isAfter(finishTime.clone().subtract(30, 'days'));
};

const DatesSchema = (t: TFunction<'translations'>) => yup.object({
  fecActaReplanteo: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.reconsideration')}"`, orden: 3 }),
  fecInicio: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.start')}"`, orden: 3 }),
  fecActaRecepcion: yup.string().test('fechasPlazos.fecActaRecepcion', { text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.beforeEndDate', {field: t('details.dates.reception')})}`}, 
    (value, context) => value ? isOnTime(context.parent): true).nullable(),
  fecFinEjecucion: yup.string().test('fechasPlazos.fecFinEjecucion', { text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.beforeEndDate', {field: t('details.dates.execFinish')})}`},
    (value, context) => value ? isOnTime(context.parent): true).nullable(),
}).required();

export const DatesSchemaFinal = (t: TFunction<'translations'>) => yup.object({
  fecActaRecepcion: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.reception')}"`, tab: 'fechasPlazos', field: 'fecActaRecepcion', orden: 3 }),
  fecFinEjecucion: yup.string().required({ text: `${t('details.validation.tab')} ${t('details.dates.tab')}: ${t('details.validation.noData')} "${t('details.dates.execFinish')}"`, tab: 'fechasPlazos', field: 'fecFinEjecucion', orden: 3 }),
}).required();

export default DatesSchema;